<template>
    <div>
        <myHead/>
        <a-spin :spinning="isLoading" tip="加载中..." >
        <div style="margin-top:20px;padding-left:20px;padding-right:20px;padding-bottom:20px">
            <a-row :gutter="20" style="margin-top:20px">
                <a-col :span="8">
                    <div>
                        <span class="title">计划类型</span>
                        <div style="padding:20px">
                            <!-- <div>
                                <a-radio-group button-style="solid" v-model:value="task.Platform">
                                    <a-radio-button :value="1">
                                        淘宝
                                    </a-radio-button>
                                    <a-radio-button     :value="2">
                                        京东
                                    </a-radio-button>
                                    <a-radio-button  :value="5">
                                        阿里巴巴
                                    </a-radio-button>
                                    <a-radio-button   :value="6">
                                        抖音
                                    </a-radio-button>
                                </a-radio-group>
                            </div> -->
                            <a-radio-group @change="changeIsPreSale" v-model:value="task.TaskType" name="radioGroup" style="margin-top:0px">
                                <a-radio :value="1">普通单</a-radio>
                                <a-radio :value="4" >新零售</a-radio> 
                            </a-radio-group>
                            <!-- <span  class="animate__animated animate__fadeInLeft"  style="color:red"  v-show="task.TaskType>1" >
                                    + 2.0 元 / 单 
                            </span> -->
                            <div style="margin-top:20px" v-if="task.TaskType==3">
                                <p>
                                    <a-date-picker  style="width:200px"	format="YYYY-MM-DD" placeholder="请选择尾款日期" v-model:value="task.FinishDate" />
                                </p>
                                <p>
                                    <a-input v-model:value="task.PreSaleTotal" style="width:200px" placeholder="任务定金总额"  prefix="￥" suffix="RMB" />
                                </p>
                                <span style="color:#6e6d7a;margin-right:20px">预售任务将按照填写的定金总额冻结本金，付尾款之后完单结算</span>
                            </div>
                            <div style="margin-top:20px" v-if="task.TaskType==4">
                                <a-select style="width:120px"
                                v-model:value="task.City"
                                >
                                    <a-select-option value="上海">上海</a-select-option>
                                    <a-select-option value="昆明">昆明</a-select-option>
                                    <a-select-option value="重庆">重庆</a-select-option>
                                    <a-select-option value="浙江">浙江</a-select-option>
                                    <a-select-option value="佛山">佛山</a-select-option>
                                </a-select>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div>
                        <span class="title">快捷导入</span>
                        <div style="padding:20px">
                            <a-row>
                                <a-col :span="24">
                                    <div style="width: 100%;overflow-x: auto;white-space: nowrap" v-if="lately.length>0">
                                        <div style="display: inline-block;margin:5px;cursor: pointer;" v-for="item in lately" :key="item.TaskId" @click="copyTask(item.TaskId)">
                                            <a-tooltip placement="bottom">
                                            <template #title>
                                                <div style="text-align:center">
                                                    <span>{{item.ShopName}}</span>
                                                    <br/>
                                                    <span>点击导入历史计划数据</span>
                                                </div>
                                            </template>
                                            <div :style="'background: url(\''+item.Img+'\') no-repeat;background-size: cover; width:150px;height:150px'">
                                                <div style="text-align:center;padding:5px;color:white;background:black;opacity:0.5">
                                                    <a-row>
                                                        <a-col :span="12">
                                                            {{item.Date}}
                                                        </a-col>
                                                        <a-col :span="12">
                                                            {{item.Num}} 单
                                                        </a-col>
                                                    </a-row>
                                                </div>
                                            </div>
                                        </a-tooltip>
                                        </div>
                                    </div>
                                    <a-empty description="昨天没有发布过计划" v-else/>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div>
                        <span class="title">文档导入</span>
                        <!-- <a href="http://image.baomai.cc/%E5%85%B3%E9%94%AE%E8%AF%8D%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"  target="_blank" style="margin-left:20px;color:#4f3cc9">关键词导入模板下载</a> -->
                        <a href="https://oss.mtpc.cc/%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%282%29%281%29.xlsx"  target="_blank" style="margin-left:20px;color:#4f3cc9">计划导入模板下载</a>
                        <div style="padding:20px">
                            <a-row :gutter="20">
                                <!-- <a-col :span="12">
                                    <div @click="importTaskExcel" class="daoru">
                                        <div style="margin-top:20px;">
                                            <inbox-outlined style="color:#bb3d6e;font-size: 48px;"></inbox-outlined>
                                        </div>
                                        <div style="padding:20px;font-size:15px;letter-spacing:2px;">
                                            <b>批量导入计划</b>
                                        </div>
                                    </div>
                                </a-col> -->
                                <a-col :span="24">
                                    <a-tooltip placement="bottom">
                                        <template #title>
                                        <span>
                                            支持导入二维码，每个链接对应一个二维码
                                            <br/>
                                            需要将二维码完全置于关键词一列
                                            <br/>
                                            如需相同链接不同二维码请额外创建一行
                                        </span>
                                        </template>
                                        <div @click="importExcel" class="daoru">
                                            <div style="margin-top:20px;">
                                                <inbox-outlined style="color:#0072E3	;font-size: 48px;"></inbox-outlined>
                                            </div>
                                            <div style="padding:20px;font-size:15px;letter-spacing:2px;">
                                                <b>批量导入计划</b>
                                            </div>
                                        </div>
                                    </a-tooltip>
                                    
                                </a-col>
                            </a-row>
                            <input type="file" ref="uploadTaskExcel" v-show="false" accept=".xls,.xlsx" @change="readTaskExcel" />
                            <input type="file" ref="uploadExcel" v-show="false" accept=".xls,.xlsx" @change="readExcel" />
                        </div>                       
                    </div>
                </a-col>
            </a-row>
            <a-row  :gutter="20" style="margin-top:0px">
                <a-col :span="24">
                    <div>
                        <span class="title">计划设置</span>
                        <div style="padding:20px">
                            <a-descriptions  bordered>
                                <a-descriptions-item label="完成日期">    
                                    <a-date-picker  :allowClear="false"	format="YYYY-MM-DD" placeholder="请选择任务日期" v-model:value="datePickerValue" />
                                </a-descriptions-item>
                                <a-descriptions-item label="开始时间">
                                    <a-select
                                    style="width:80px"
                                    v-model:value="task.StartHour">
                                        <a-select-option :value="8">08:00</a-select-option>
                                        <a-select-option :value="9">09:00</a-select-option>
                                        <a-select-option :value="10">10:00</a-select-option>
                                        <a-select-option :value="11">11:00</a-select-option>
                                        <a-select-option :value="12">12:00</a-select-option>
                                        <a-select-option :value="13">13:00</a-select-option>
                                        <a-select-option :value="14">14:00</a-select-option>
                                        <a-select-option :value="15">15:00</a-select-option>
                                        <a-select-option :value="16">16:00</a-select-option>
                                        <a-select-option :value="17">17:00</a-select-option>
                                        <a-select-option :value="18">18:00</a-select-option>
                                        <a-select-option :value="19">19:00</a-select-option>
                                        <a-select-option :value="20">20:00</a-select-option>
                                        <a-select-option :value="21">21:00</a-select-option>
                                        <a-select-option :value="22">22:00</a-select-option>
                                        <a-select-option :value="23">23:00</a-select-option>
                                    </a-select>
                                </a-descriptions-item>
                                <a-descriptions-item label="结束时间">
                                    <a-select v-model:value="task.EndHour" style="width:80px">
                                        <a-select-option :value="8">08:00</a-select-option>
                                        <a-select-option :value="9">09:00</a-select-option>
                                        <a-select-option :value="10">10:00</a-select-option>
                                        <a-select-option :value="11">11:00</a-select-option>
                                        <a-select-option :value="12">12:00</a-select-option>
                                        <a-select-option :value="13">13:00</a-select-option>
                                        <a-select-option :value="14">14:00</a-select-option>
                                        <a-select-option :value="15">15:00</a-select-option>
                                        <a-select-option :value="16">16:00</a-select-option>
                                        <a-select-option :value="17">17:00</a-select-option>
                                        <a-select-option :value="18">18:00</a-select-option>
                                        <a-select-option :value="19">19:00</a-select-option>
                                        <a-select-option :value="20">20:00</a-select-option>
                                        <a-select-option :value="21">21:00</a-select-option>
                                        <a-select-option :value="22">22:00</a-select-option>
                                        <a-select-option :value="23">23:00</a-select-option>
                                    </a-select>
                                </a-descriptions-item>                  

                                <a-descriptions-item :span="1" label="进店方式" >
                                    <a-select style="width:112px" v-model:value="task.KeyType">
                                        <a-select-option :value="0">关键字</a-select-option>
                                        <a-select-option :value="1">口令</a-select-option>
                                        <a-select-option :value="2">扫码</a-select-option>
                                    </a-select>
                                </a-descriptions-item>
                                <a-descriptions-item :span="1" label="需要核销码" >
                                    <a-switch   checked-children="是" un-checked-children="否"  v-model:checked="task.NeedCode"    />
                                </a-descriptions-item>

                                <a-descriptions-item :span="1" label="性别要求" >
                                    <a-radio-group  name="radioGroup" v-model:value="task.SwipeGender">
                                            <a-radio :value="0">不限</a-radio>
                                            <a-radio :value="1">男</a-radio>
                                            <a-radio :value="2">女</a-radio>
                                        </a-radio-group>
                                    <span  class="animate__animated animate__fadeInLeft"  style="color:red"  v-show="task.SwipeGender>0" >
                                         + 0.5 元 / 单                                        
                                    </span>
                                </a-descriptions-item>


                                <a-descriptions-item :span="1"  label="是否开票" >
                                        <a-switch @change="changeNeedInvoice" v-model:checked="needInvoice"    />
                                        <div v-if="needInvoice" style="margin-top:10px">
                                            <a-textarea  placeholder="请填写开票信息" v-model:value="task.InvoiceInfo" :rows="8" show-count :maxlength="255" />
                                        </div>
                                </a-descriptions-item>

                                <a-descriptions-item :span="1"  label="超级验号" >
                                        <a-switch @change="changeNeedInvoice" v-model:checked="task.DemandHao"    />
                                        <span  class="animate__animated animate__fadeInLeft"  style="color:red"  v-show="task.DemandHao>0" >
                                         + 0.5 元 / 单                                        
                                    </span>
                                </a-descriptions-item>

                                <a-descriptions-item :span="1"  label="收藏加购" >
                                        <a-switch @change="changeNeedInvoice" v-model:checked="task.DemandHot"    />
                                        <span  class="animate__animated animate__fadeInLeft"  style="color:red"  v-show="task.DemandHot>0" >
                                         + 0.5 元 / 单                                        
                                    </span>
                                </a-descriptions-item>

                                 <a-descriptions-item label="特殊要求" :span="3">
                                    <div>
                                        <a-textarea placeholder="其他需求可以在这里填写，如假聊、收藏加购等" v-model:value="task.Remark" :rows="2" show-count :maxlength="100" />
                                    </div>
                                </a-descriptions-item>
                             </a-descriptions>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-row :gutter="20" style="margin-top:0px">
                <a-col :span="8">
                    <div>
                        <span class="title">商品数据</span>
                        <div style="padding:20px">
                            <a-descriptions :column="1" bordered>         
                                <a-descriptions-item  label="商品主图">
                                    <a-upload 
                                        v-model:file-list="fileList"
                                        :width="200"
                                            :multiple="false"
                                            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                                            :action="baseUrl+'/Task/PushImg'"
                                            list-type="picture-card"
                                            @change="(info) => uploadImg(info)"
                                            @preview="handlePreview"
                                            >
                                            <div v-if="task.Img==''&&uploading==false">
                                                <PlusOutlined />
                                            </div>
                                        </a-upload>
                                 </a-descriptions-item>  
                                <a-descriptions-item label="商品链接" >
                                    <a-input-search
                                    v-model:value="task.Url"
                                    placeholder="复制宝贝链接，一键抓取主图等信息"
                                    enter-button="获取商品信息"
                                    style="width: 100%"
                                    :loading="searching"
                                    @search="onSearch"/>
                                </a-descriptions-item>
                                <a-descriptions-item  label="商品标题">
                                    <a-input v-model:value="task.Title" style="width:100%" placeholder="商品标题" />
                                </a-descriptions-item>
                                <a-descriptions-item  label="店铺名称">
                                    <a-input v-model:value="task.ShopName" style="width:100%" placeholder="店铺名称" />
                                </a-descriptions-item>
                                <a-descriptions-item  label="展示价格">
                                    <a-input prefix="￥"  v-model:value="task.ShowPrice"  suffix="RMB" style="width:150px" />
                                 </a-descriptions-item>
                                  <a-descriptions-item  label="发货地址">
                                    <a-input    v-model:value="task.SendAddress"   style="width:150px" />
                                 </a-descriptions-item>
                             </a-descriptions>
                        </div>
                    </div>
                </a-col>
                <a-col :span="16">
                    <div>
                        <span class="title">任务内容</span>
                        <a   @click="addRow(1)" style="margin-left:20px;color:#4f3cc9">添加一行</a>
                            <span style="line-height:20px;margin-left:10px;" v-show="task.Details.length>0">
                                 共 <a-badge style="margin-top:-2px" :count="task.Details.length" /> 行
                            </span>
                        <div style="padding:20px">
                            <a-table  :rowKey="index => index.toString()"  :pagination="false" :data-source="task.Details" :columns="columns">
                                <template #keyWordsTitle>
                                    <span v-if="task.KeyType==0">关键字</span>
                                    <span v-else-if="task.KeyType==1">淘口令</span>
                                    <span v-else>二维码</span>
                                </template>

                               <template  #recordSpec="{ record,index }">
                                    <div>
                                         <a-input  @paste="pastingSpec(index)" :maxlength="30" v-model:value="record.Spec" placeholder="拍下规格"  />
                                    </div>
                                </template>
                                <template #recordkeyWords="{ record,index }">
                                    <div>
                                         <a-input @paste="pastingKeyWords(index)" v-if="task.KeyType==0"  :maxlength="255"  v-model:value="record.KeyWords" placeholder="搜索关键词"  />
                                         <a-input @paste="pastingKeyWords(index)" v-else-if="task.KeyType==1"  :maxlength="255"  v-model:value="record.KeyWords" placeholder="淘口令"  />
                                         <a-upload v-else-if="task.KeyType==2"
                                            v-model:file-list="codeFileList[index]"
                                            :multiple="false"
                                            accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                                            :action="baseUrl+'/Task/PushImg'"
                                            list-type="picture-card"
                                            @change="(info) => keyWordChange(info, record)"
                                            @preview="handlePreview"
                                            >
                                            <div v-if="record.KeyWords==''&&uploading==false">
                                                <PlusOutlined />
                                            </div>
                                        </a-upload>
                                    </div>
                                </template>
                                <template  #price="{ record,index }">
                                    <div>
                                        <a-input
                                        @paste="pastingPrice(index)"
                                        :min="0"
                                        style="width:100px"
                                        v-model:value="record.Price"
                                        :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                        :parser="value => value.replace(/\￥\s?|(,*)/g, '')"/>
                                    </div>
                                </template>
                                <template #num="{ record,index }">
                                    <div>
                                        <a-input
                                        @paste="pastingNum(index)" 
                                        :min="1"
                                        v-model:value="record.Num"/>
                                    </div>
                                </template>
                                <template #action="{ index }">
                                    <a-tooltip placement="bottom">
                                        <template #title>
                                            <div>删除这一行</div>
                                        </template>
                                        <a @click="remove(index)"><DeleteOutlined /></a>
                                    </a-tooltip>
                                    <a-divider type="vertical" />
                                   
                                    <a-tooltip placement="bottom">
                                        <template #title>
                                            <div>复制这一行</div>
                                        </template>
                                         <a @click="copyRow(index)"><CopyOutlined /> </a>
                                    </a-tooltip>
                                    <a-divider type="vertical" />
                                </template>
                            </a-table>
                        </div>
                    </div>
                </a-col>
            </a-row>
            <a-row :gutter="20" style="margin-top:20px;margin-bottom:20px">
                <a-col :span="24">
                     <a-button :loading="paying" style="width:250px;float:right;margin-right:10%" @click="goPay"  class="my-btn">
                        <span>
                            去支付
                        </span>
                    </a-button>
                </a-col>
            </a-row>
        </div>
        </a-spin>
        <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    <a-modal
    :confirmLoading="paying"
    cancelText="取消" okText="发布并支付"
      v-model:visible="taskImportVisible"
      width="100%"
      wrap-class-name="full-modal"
      @ok="handleOk"
    >
        <template #title>
        <div style="width: 100%; font-size:14px;letter-spacing:1px">
            <span>预计本金：<b>{{importExpectedPrice.toFixed(2)}}</b></span>
            <span style="margin-left:50px">预计佣金：<b>{{importExpectedCommission.toFixed(2)}}</b></span>
            <span style="margin-left:50px">总计本佣：<b>{{(importExpectedCommission+importExpectedPrice).toFixed(2)}}</b></span>
            <span style="margin-left:50px">总单数： <a-tag color="#2db7f5">{{importNum}}</a-tag></span>
            <span style="margin-left:50px">完成日期：<a-date-picker  :allowClear="false"	format="YYYY-MM-DD" placeholder="请选择任务日期" v-model:value="datePickerValue" /></span>
        </div>
      </template>
        <a-table :rowKey="record => record.Url"  :scroll="{ y: tableHeight }"  :dataSource="taskList" :pagination="false" :columns="taskColumns" >
            <template #url="{ record }">
                <a style="color:#ec5e95" :href="record.Url" target="view_window">
                    {{record.Title}}
                </a>
            </template>
            <template #expectedPrice="{ text }">
                <span style="font-weight:600">
                    {{text.toFixed(2)}}
                </span>
            </template>
            <template #total="{ text }">
                <span style="font-weight:600">
                    {{text.toFixed(2)}}
                </span>
            </template>
            <template #expectedCommission="{ text }">
                <span style="font-weight:600">
                    {{text.toFixed(2)}}
                </span>
            </template>
            <template #detail="{record}">
                <div v-for="(item,i) in record.Details" :key="i" style="text-align:center;line-height:50px;">
                    <div :style="i>0?'border:1px dashed #C0C0C0;margin-top:20px':'border:1px dashed #C0C0C0'">
                        <a-row>
                            <a-col :span="19">
                                <a-tag color="processing">{{item.Spec}}</a-tag>
                            </a-col>
                            <a-col :span="5">
                                <b>{{item.Price.toFixed(2)}}</b>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="19">
                                <a v-if="item.KeyWords.indexOf('http://')>-1" target="_blank" :href="item.KeyWords">点击查看二维码</a>
                                <!-- <b v-else></b> -->
                                <a-input v-model:value="item.KeyWords" style="width:70%" />
                            </a-col>
                            <a-col :span="5">
                                
                                <a-tag  color="#2db7f5">单量</a-tag> <a-input-number  style="width:60px" v-model:value="item.Num" :min="1"  />
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </template>
            <template #num="{ text }">
                <a-tag color="#2db7f5">{{text}}</a-tag>
            </template>
            <template #img="{ text }">
                <a-image
                    :width="60" :height="60"
                    :src="text"/>
            </template>
        </a-table>
    </a-modal>
    </div>
    
</template>
<script>
import {  ref } from 'vue';
import myHead from '../components/myHead'
import { message } from 'ant-design-vue';
import { DeleteOutlined,CopyOutlined,PlusOutlined,InboxOutlined} from '@ant-design/icons-vue';
import {uri,taskPreCreate,taskGetLatelyTask,taskCopyTask,taskGetInfoByUrl,taskImportTaskByUrl,taskPush,taskCopyListReq,memberGetInvoiceInfo,pushExlReq} from '@/request.js'
import { useRouter } from 'vue-router';

import XLSX from 'xlsx'
function formatDate(date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
}
import moment from 'moment';

export default {
    components:{ myHead,DeleteOutlined,CopyOutlined,PlusOutlined,InboxOutlined  },
    setup() {
        const needInvoice=ref(false);
        const importNum=ref(0);
        const importExpectedCommission=ref(0);
        const importExpectedPrice=ref(0);
        const tableHeight=ref(0);
        tableHeight.value=document.documentElement.clientHeight-180;
        const taskImportVisible=ref(false);
        const taskList=ref({});
        const uploadTaskExcel=ref();
        const fileList=ref([]);
        const lately=ref([]);
        taskGetLatelyTask({},(res)=>{
            lately.value=res.Result;
        })
        const uploadExcel = ref(null);
        const router=useRouter();
        const previewImage=ref("");
        const previewVisible=ref(false);
        const baseUrl=ref(uri);
        const paying=ref(false);
        const uploading=ref(false);
        const searching=ref(false);
        const isLoading=ref(false);
        const datePickerValue=ref();
        if(new Date().getHours()>18){
             datePickerValue.value=moment(formatDate(new Date((new Date()/1000+86400)*1000)), 'YYYY/MM/DD');
        }else{
            datePickerValue.value=moment(formatDate(new Date()), 'YYYY/MM/DD');
        }
        const changeNeedInvoice=()=>{
            if(needInvoice.value){
                memberGetInvoiceInfo({},(res)=>{
                    if(res.Result){
                        task.value.InvoiceInfo=res.Result;
                    }
                })
            }else{
                task.value.InvoiceInfo="";
            }
        }
        const task=ref({
            DemandHao:false,
            DemandHot:false,
            NeedCode:false,
            City:"",
            SendAddress:"",
            Platform:0,
            Url:"",
            Title:"",
            Img:"",
            ShowPrice:0,
            ShopName:"",
            TaskType:1,
            FinishDate:null,
            PreSaleTotal:0,
            StartHour:8,
            EndHour:22,
            SwipeGender:0,
            KeyType:0,
            Remark:"",
            ExpectedOverTime:'',
            Details:[
                {
                    Price:0,
                    BuyNum:0,
                    Spec:"",
                    KeyWords:"",
                    Num:1,
                    ChildList:[],
                }
            ],
        });
        const codeFileList=ref([]);
        const columns =ref([
                {
                    dataIndex: 'keyWords',
                    key: 'keyWords',
                    slots: { customRender: 'recordkeyWords',title: 'keyWordsTitle' },
                },
                {
                    title: '规格',
                    dataIndex: 'Spec',
                    key: 'Spec',
                    slots: { customRender: 'recordSpec' },
                },
                {
                    title: '实付金额',
                    dataIndex: 'Price',
                    key: 'Price',
                    slots: { customRender: 'price' },
                },
                {
                    title: '单数',
                    dataIndex: 'Num',
                    key: 'Num',
                    slots: { customRender: 'num' },
                },
                {
                    title: '操作',
                    key: 'action',
                    slots: { customRender: 'action' },
                },
        ]);
        if(router.currentRoute.value.query.copy){
            isLoading.value=true;
            taskCopyTask({taskId:router.currentRoute.value.query.copy},(res)=>{
                task.value=res.Result;
                var array=new Array();
                array.push({
                    name:res.Result.Img,
                    response:{
                        IsSuccess:true,
                        Message:res.Result.Img,
                    },
                    status:"done",
                    thumbUrl:res.Result.Img,
                    type:"image/jpeg",
                    uid:res.Result.Img,
                });
                fileList.value=array;
                task.value.Details.forEach(x=>{
                    var codeArray=new Array();
                    if(task.value.KeyType==2){
                        codeArray.push({
                            name:x.KeyWords,
                            response:{
                                IsSuccess:true,
                                Message:x.KeyWords,
                            },
                            status:"done",
                            thumbUrl:x.KeyWords,
                            type:"image/jpeg",
                            uid:x.KeyWords,
                        });
                    }
                    codeFileList.value.push(codeArray);
                })
                isLoading.value=false;
            })
        }
        else if(router.currentRoute.value.query.copylist){
            isLoading.value=true;
            taskCopyListReq({idJson:router.currentRoute.value.query.copylist},(res)=>{
                if(res.IsSuccess){
                    taskList.value=res.Result;
                    res.Result.forEach(x=>{
                        importNum.value+=x.Num,
                        importExpectedCommission.value+=x.ExpectedCommission,
                        importExpectedPrice.value+=x.ExpectedPrice
                    })
                    taskImportVisible.value=true;
                }else{
                    isLoading.value=false;
                    message.error(res.Message);
                    return false;
                }
                isLoading.value=false;
            })
        }
        else{
            isLoading.value=false;
        }
        const addRow=(num)=>{
            for(var i=0;i<num;i++){
                codeFileList.value.push(new Array());
                let row={
                    Price:0,
                    BuyNum:0,
                    Spec:"",
                    KeyWords:"",
                    Num:1,
                    ChildList:[],
                }
                task.value.Details.push(row);
            }
        };
        const remove=(index)=>{
            task.value.Details.splice(index, 1);
        };
        const copyRow=(index)=>{
            if(task.value.Details.length>0){
                let model=JSON.parse(JSON.stringify(task.value.Details[index]));
                task.value.Details.push(model);
                codeFileList.value.push((JSON.parse(JSON.stringify(codeFileList.value[index]))));
            }else{
                message.error("第一行没有内容！"); 
            }
        };
        const changeIsPreSale=()=>{
 
            task.value.FinishDate=null;
            if(task.value.TaskType==4){
                task.value.City="上海";
            }else{
                task.value.City="";
            }
        };
        const keyWordChange=(info,record)=>{
            if(info.file.status=="uploading"){
                uploading.value=true;
            }
            else if(info.file.status=="removed"){
                record.KeyWords="";                
            }
            else{
                if(info.file.response){
                    var res=info.file.response;
                    if(res.IsSuccess){
                        uploading.value=false;
                        record.KeyWords= res.Message;
                    }else{
                        message.error(res.Message);
                    }
                }
            }
        };

        const uploadImg=(info)=>{
            if(info.file.status=="uploading"){
                uploading.value=true;
            }
            else if(info.file.status=="removed"){
                task.value.Img="";                
            }
            else{
                if(info.file.response){
                    var res=info.file.response;
                    if(res.IsSuccess){
                        uploading.value=false;
                        task.value.Img= res.Message;
                    }else{
                        message.error(res.Message);
                    }
                }
            }
        };
        const onSearch=()=>{
            isLoading.value=true;
            taskGetInfoByUrl({url:task.value.Url},(res)=>{
                if(res.IsSuccess){
                    var array=new Array();
                    array.push({
                        name:res.Result.Img,
                        response:{
                            IsSuccess:true,
                            Message:res.Result.Img,
                        },
                        status:"done",
                        thumbUrl:res.Result.Img,
                        type:"image/jpeg",
                        uid:res.Result.Img,
                    });
                    fileList.value=array;
                    task.value.ShopName=res.Result.ShopName;
                    task.value.Img=res.Result.Img;
                    task.value.Url=res.Result.Url;
                    task.value.Title=res.Result.Title;
                    task.value.SendAddress=res.Result.SendAddress;
                    task.value.ShowPrice=res.Result.ShowPrice;
                }else{
                    message.error(res.Message);
                }
                isLoading.value=false;
            })
        };
        const goPay=()=>{
            console.log(datePickerValue.value);
            if(paying.value){
                return;
            }
            if(!datePickerValue.value){
                message.error("请选择期望完成日期"); 
                return;
            }
            if(task.value.Details.length==0){
                message.error("您没有添加任务数据"); 
                return;
            }
            paying.value=true;
            if(task.value.FinishDate){
                task.value.FinishDate=format(new Date(task.value.FinishDate),"yyyy-MM-dd 00:00:00");
            }
            task.value.ExpectedOverTime=format(new Date(datePickerValue.value),"yyyy-MM-dd 00:00:00");
            taskPreCreate({json:JSON.stringify(task.value)},(res)=>{
                if(res.IsSuccess){
                    router.push({path:'/pay',query:{code:res.Result.Code}});
                }else{
                    message.error(res.Message); 
                }
                paying.value=false;
            })
         };
        const format=(date,fmt)=> {
            let o = {
                "M+": date.getMonth() + 1,         //月份
                "d+": date.getDate(),          //日
                "h+": date.getHours(),          //小时
                "m+": date.getMinutes(),         //分
                "s+": date.getSeconds(),         //秒
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                "S": date.getMilliseconds()       //毫秒
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            }
            for (let k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                }
            }
            return fmt;
        };
        const copyTask=(taskId)=>{
            isLoading.value=true;
            taskCopyTask({taskId},(res)=>{
                task.value=res.Result;
                var array=new Array();
                array.push({
                    name:res.Result.Img,
                    response:{
                        IsSuccess:true,
                        Message:res.Result.Img,
                    },
                    status:"done",
                    thumbUrl:res.Result.Img,
                    type:"image/jpeg",
                    uid:res.Result.Img,
                });
                fileList.value=array;
                task.value.Details.forEach(x=>{
                    var codeArray=new Array();
                    if(task.value.KeyType==2){
                        codeArray.push({
                            name:x.KeyWords,
                            response:{
                                IsSuccess:true,
                                Message:x.KeyWords,
                            },
                            status:"done",
                            thumbUrl:x.KeyWords,
                            type:"image/jpeg",
                            uid:x.KeyWords,
                        });
                    }
                    codeFileList.value.push(codeArray);
                })
                isLoading.value=false;
            })
        }
        const handlePreview=(file)=>{
            previewImage.value = file.response.Message;
            previewVisible.value = true;
        };
        const importTaskExcel=()=>{
            uploadTaskExcel.value.click();
        };
        const importExcel=()=>{
            uploadExcel.value.click();
        };
        const readTaskExcel=(e)=>{
            importNum.value=0;
            importExpectedCommission.value=0;
            importExpectedPrice.value=0;
            isLoading.value=true;
            const files = e.target.files;
            if (files.length <= 0) {
                //如果没有文件名
                isLoading.value=false;
                return false;
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
            try {
                    const data = ev.target.result;
                    const workbook = XLSX.read(data, {
                        type: "binary"
                    });
                    const wsname = workbook.SheetNames[0]; //取第一张表
                    const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //获取到XLSX表格中的数据,并生成json格式的数据类型
                    let array=[];
                    var isOk=true;
                    console.log(ws);
                    for(var i=0;i<ws.length;i++){
                        var value=ws[i];
                        if(value.商品链接!='商品链接')
                        {
                            if(value.单量&&!isNaN(Number(value.单量))&&parseInt(value.单量)>0){
                                if(value.商品链接){
                                if(value.商品链接.indexOf("new.m.taobao.com")<0
                                &&value.商品链接.indexOf("detail.m.tmall.com")<0
                                &&value.商品链接.indexOf("h5.m.taobao.com")<0
                                &&value.商品链接.indexOf("detail.1688.com")<0
                                &&value.商品链接.indexOf("item.taobao.com")<0
                                &&value.商品链接.indexOf("detail.tmall.com")<0
                                &&value.商品链接.indexOf("haohuo.jinritemai.com")<0
                                &&value.商品链接.indexOf("v.douyin.com")<0
                                &&value.商品链接.indexOf("item.jd.com")<0
                                &&value.商品链接.indexOf("item.jd.com")<0){
                                    isLoading.value=false;
                                    message.error("("+(value.__rowNum__+1)+"行）链接填写错误");
                                    isOk=false;
                                    return;
                                }

                                if(value.关键词){
                                    if(isNaN(Number(value.下单价格))){
                                        
                                        isLoading.value=false;
                                        message.error("("+(value.__rowNum__+1)+"行）下单价格填写错误");
                                        isOk=false;
                                        return;
                                    }
                                    else if(isNaN(Number(value.单量))){
                                        isLoading.value=false;
                                        message.error("("+(value.__rowNum__+1)+"行）单量填写错误");
                                        isOk=false;
                                        return;
                                    }
                                    else{
                                        array.push({
                                            Url:value.商品链接,
                                            Remark:value.备注,
                                            Details:[{
                                                KeyWords:value.关键词,
                                                Price:value.下单价格,
                                                Num:value.单量,
                                                Spec:value.规格,
                                                BuyNum:0,
                                            }]
                                        })
                                    }
                                }else{
                                    isLoading.value=false;
                                    isOk=false;
                                    message.error("("+(value.__rowNum__+1)+"行）没有关键词");
                                    return;
                                }
                            }else{
                                if(isNaN(Number(value.下单价格))){
                                        isLoading.value=false;
                                        message.error("("+(value.__rowNum__+1)+"行）下单价格填写错误");
                                        isOk=false;
                                        return;
                                    }
                                    else if(isNaN(Number(value.单量))){
                                        isLoading.value=false;
                                        message.error("("+(value.__rowNum__+1)+"行）单量填写错误");
                                        isOk=false;
                                        return;
                                    }
                                    else{
                                        if(value.关键词){
                                            array[array.length-1].Details.push(
                                                {
                                                    KeyWords:value.关键词,
                                                    Price:value.下单价格,
                                                    Num:value.单量,
                                                    Spec:value.规格,
                                                    BuyNum:0,
                                                }
                                            )
                                        }
                                        else{
                                            isLoading.value=false;
                                            isOk=false;
                                            message.error("("+(value.__rowNum__+1)+"行）没有关键词");
                                            return;
                                        }
                                    }

                            }
                            }
                            
                        }
                    }
                    // ws.forEach((value) => {

                    // })
                    if(isOk){
                        taskImportTaskByUrl({json:JSON.stringify(array)},(res)=>{
                        if(res.IsSuccess){
                            taskList.value=res.Result;
                            res.Result.forEach(x=>{
                                importNum.value+=x.Num,
                                importExpectedCommission.value+=x.ExpectedCommission,
                                importExpectedPrice.value+=x.ExpectedPrice
                            })
                            taskImportVisible.value=true;
                        }else{
                            isLoading.value=false;
                            message.error(res.Message);
                            return false;
                        }
                        isLoading.value=false;
                    })
                    
                    }

                } catch  {
                    
                    message.error("填写数据错误或模板错误");
                    isLoading.value=false;
                    return;
                }
            };
            fileReader.readAsBinaryString(files[0]);
        };
        const readExcel=(e)=>{
            isLoading.value=true;
            const files = e.target.files;
            if (files.length <= 0) {
                //如果没有文件名
                isLoading.value=false;
                return false;
            }
            const formData = new FormData();
            formData.append("file",files[0],files[0].name);
            pushExlReq(formData,(res)=>{
                if(res.IsSuccess){
                    taskList.value=res.Result;
                    res.Result.forEach(x=>{
                        importNum.value+=x.Num,
                        importExpectedCommission.value+=x.ExpectedCommission,
                        importExpectedPrice.value+=x.ExpectedPrice
                    })
                    taskImportVisible.value=true;
                }else{
                    isLoading.value=false;
                    message.error(res.Message);
                    return false;
                }
                isLoading.value=false;
            })
        };
        const pastingKeyWords=(index)=>{
            navigator.clipboard
            .readText()
            .then((v) => {
                var values=v.split("\r\n");
                values.forEach((x,i)=>{
                    if(x){
                        if(task.value.Details[index+i]){
                            task.value.Details[index+i].KeyWords=x;
                        }else{
                            codeFileList.value.push(new Array());
                            task.value.Details.push({
                                Price:0,
                                BuyNum:0,
                                Spec:"",
                                KeyWords:x,
                                Num:1,
                                ChildList:[],
                            })
                        }
                    }
                });
            })
            .catch(() => {
                message.error("访问剪贴板错误"); 
            });

        };
        const pastingSpec=(index)=>{
            navigator.clipboard
            .readText()
            .then((v) => {
                var values=v.split("\r\n");
                values.forEach((x,i)=>{
                    if(x){
                        if(task.value.Details[index+i]){
                            task.value.Details[index+i].Spec=x;
                        }else{
                            codeFileList.value.push(new Array());
                            task.value.Details.push({
                                Price:0,
                                BuyNum:0,
                                Spec:x,
                                KeyWords:"",
                                Num:1,
                                ChildList:[],
                            })
                        }
                    }
                });
            })
            .catch(() => {
                message.error("访问剪贴板错误"); 
            });
        };
        const pastingPrice=(index)=>{
            navigator.clipboard
            .readText()
            .then((v) => {
                var values=v.split("\r\n");
                values.forEach((x,i)=>{
                    if(x){
                        if(task.value.Details[index+i]){
                            task.value.Details[index+i].Price=x;
                        }else{
                            codeFileList.value.push(new Array());
                            task.value.Details.push({
                                Price:x,
                                BuyNum:0,
                                Spec:"",
                                KeyWords:"",
                                Num:1,
                                ChildList:[],
                            })
                        }
                    }
                });
            })
            .catch(() => {
                message.error("访问剪贴板错误"); 
            });
        };
        const pastingNum=(index)=>{
            navigator.clipboard
            .readText()
            .then((v) => {
                var values=v.split("\r\n");
                values.forEach((x,i)=>{
                    if(x){
                        if(task.value.Details[index+i]){
                            task.value.Details[index+i].Num=x;
                        }else{
                            codeFileList.value.push(new Array());
                            task.value.Details.push({
                                Price:0,
                                BuyNum:0,
                                Spec:"",
                                KeyWords:"",
                                Num:x,
                                ChildList:[],
                            })
                        }
                    }
                });
            })
            .catch(() => {
                message.error("访问剪贴板错误"); 
            });
        };
        const handleOk=()=>{
            paying.value=true;
            taskPush({
                taskType:task.value.TaskType,
                city:task.value.City,
                json:JSON.stringify(taskList.value),
                invoiceInfo:task.value.InvoiceInfo,
                date:format(new Date(datePickerValue.value),"yyyy-MM-dd 00:00:00"),
                swipeGender:task.value.SwipeGender,
                needCode:task.value.NeedCode,
                startHour:task.value.StartHour,
                endHour:task.value.EndHour,
                keyType:task.value.KeyType,
                demandHao:task.value.DemandHao,
                demandHot:task.value.demandHot
            },(res)=>{
                if(res.IsSuccess){
                    message.success("发布成功"); 
                    taskList.value=[];
                    taskImportVisible.value=false;
                }else{
                    message.error(res.Message); 
                }
                paying.value=false;
            })
        }
        return {
            needInvoice,
            changeNeedInvoice,
            handleOk,
            importExpectedPrice,
            importExpectedCommission,
            importNum,
            tableHeight,
            taskList,
            taskImportVisible,
            uploadTaskExcel,
            readTaskExcel,
            importTaskExcel,
            pastingSpec,
            pastingPrice,
            pastingNum,
            pastingKeyWords,
            uploadExcel,
            readExcel,
            importExcel,
            task,
            changeIsPreSale,
            myHead,
            isLoading,
            datePickerValue,
            searching,
            columns,
            addRow,
            codeFileList,
            keyWordChange,
            copyRow,
            remove,
            onSearch,
            paying,
            goPay,
            baseUrl,
            handlePreview,
            previewVisible,
            previewImage,
            uploading,
            uploadImg,
            lately,
            fileList,
            copyTask,
            taskColumns: [
                {
                    title: '商品主图',
                    dataIndex: 'Img',
                    key: 'img',
                    slots: { customRender: 'img' },
                },
                {
                    title: '商品信息',
                    dataIndex: 'Url',
                    key: 'url',
                    slots: { customRender: 'url' },
                },
                {
                    title: '店铺名称',
                    dataIndex: 'ShopName',
                    key: 'ShopName',
                    slots: { customRender: 'shopName' },
                },
                {
                    title: '预计本金',
                    dataIndex: 'ExpectedPrice',
                    key: 'expectedPrice',
                    slots: { customRender: 'expectedPrice' },
                },
                {
                    title: '预计佣金',
                    dataIndex: 'ExpectedCommission',
                    key: 'expectedCommission',
                    slots: { customRender: 'expectedCommission' },
                },
                {
                    title: '预计本佣',
                    dataIndex: 'Total',
                    key: 'total',
                    slots: { customRender: 'total' },
                },
                {
                    title: '单数',
                    dataIndex: 'Num',
                    key: 'num',
                    slots: { customRender: 'num' },
                },
                {
                    width:"40%",
                    title: '明细',
                    key: 'detail',
                    slots: { customRender: 'detail' },
                },
                {
                    title: '备注',
                    dataIndex: 'Remark',
                    key: 'remark',
                },
            ],
        };
  },
}
</script>
<style scoped>
    .prase-btn{
        cursor: pointer;
    }
    .title{
        font-weight:50;font-size:16px;letter-spacing:1px;border-left:3px solid #84C1FF	;padding-left:10px
    }
.item-title{
color: #fff;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.daoru{
    width: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
}
.daoru:hover {
    border-color: #0072E3	;
} 
</style>
<style>
.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}
.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}
.full-modal .ant-modal-body {
  flex: 1;
  padding:0px
}

</style>